import { hideModal } from 'configs/services';
import { React, PropTypes, connect, useHistory } from 'libraries';
import { ModalSelector } from 'modules';

const Modal = ({ data }) => {
  let history = useHistory();

  const GoToDetail = () => {
    history.push(`/${data.id}`)
    hideModal();
  };

  return (
    <div className={`Modal ${data.show ? 'Modal--show' : ''}`}>
      <div className="Modal__modal" onClick={() => hideModal()} />
      <div className="Modal__box">
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          <div className="" style={{width: '30%', padding: 8}}>
            <img 
              src={data.poster} alt={data.title} 
              style={{width: '100%', borderRadius: 11, cursor: 'pointer'}}
              onClick={GoToDetail } 
            />
          </div>
          <div style={{width: "70%", padding: 8}}>
            <h3 className="Modal__title" style={{cursor: 'pointer'}} onClick={GoToDetail}>{data.title}</h3>
            <div className="Modal__description">
              <span>Type: {data.description}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const reduxState = state => ({
  data: ModalSelector(state)
});

Modal.propTypes = {
  data: PropTypes.object.isRequired
};

export default connect(reduxState)(Modal);