import { handleAsync } from "helpers";
import { API } from "configs";
import { store, setMovies } from "modules";
const { dispatch } = store;

/**
 * a Service for get movie list
 * @params apikey=d1490605
 */

export const getMovieList = async(payload = {}) => {
  try {
    const [res, err] = await handleAsync(API.omdbMovies(payload));
    if(err) throw err;
    return res;
  } catch (error) {
    throw error;
  }
}


/**
 * a Service for get detail movie
 * @params apikey=d1490605
 */

export const getDetailMovieList = async(payload = {}) => {
  try {
    const [res, err] = await handleAsync(API.omdbMovies(payload));
    if(err) throw err;
    const {data} = res.axiosResponse
    dispatch(setMovies(data))
    return res;
  } catch (error) {
    throw error;
  }
}