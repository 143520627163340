
import { HomePages, SinglePage } from "pages";

const route = [
  {
    name: "beranda",
    path: '/',
    exact: true,
    component: HomePages
  },
  {
    name: "single-pages",
    path: '/:id',
    exact: true,
    component: SinglePage
  },
  {
    name: "error",
    path: '*',
    exact: true,
    component: () => {
      return(
        <div>Error Pages</div>
      )
    }
  }
];

export default route;