import { store, setModal, clearModal } from 'modules';

const { dispatch } = store;
const state = store.getState();

/**
 * a Service for showing modal
 * @param {*} payload a payload require state of modal reducer
 */
export const showModal = (payload = {}) => {
  const { modal } = state;

  // clearing modal state in reducer
  if (modal.show) {
    dispatch(setModal());
  }
  dispatch(setModal({ ...payload, show: true }));
  return payload;
};

/**
 * a Service for hiding modal
 */
export const hideModal = () => {
  dispatch(clearModal());
  return true;
};