import { AppHeader } from "components/templates";
import {Modal} from "components/molecules";
import { React } from "libraries";

const AppLayout = ({
  title,
  children
}) => {
  document.title = title
  return(
    <div className="appLayout" style={{position:'relative'}}>
      <AppHeader />
      <div className="appLayout__routes">
        {children}
      </div>
      <Modal />
    </div>
  )
};

export default AppLayout;
