import { React } from "libraries";

const AppHeader = () => {
  const pathname = window.location.pathname;
  return(
    <div className="appHeader">
      <div className="appHeader__wrap">
        {pathname === '/' ? (
          <div style={{padding: 14}}>Assigment Stockbit</div>
        ): null}
      </div>
    </div>
  )
};

export default AppHeader;
