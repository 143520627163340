const activeConfig = 'dev'
// https://www.omdbapi.com/?apikey=d1490605&i=tt0348150
const config = {
  dev: {
    appName: 'imbd-stockbit-assigment',
    url: {
      api: 'https://www.omdbapi.com',
      assets: 'https://www.omdbapi.com',
      origin: 'https://www.omdbapi.com'
    },
  },
  prod: {
    appName: 'imbd-stockbit-assigment',
    url: {
      api: 'https://www.omdbapi.com',
      origin: 'https://www.omdbapi.com'
    }
  }
};

const appConfig = config[activeConfig];

export default appConfig;