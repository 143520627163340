import { React, PropTypes, useHistory } from "libraries";
import { showModal } from "configs";

const Card = ({
  data,
  customRef,
  showInfo
}) => {
const handleShowModal = () => {
    showModal({
      id: data.imdbID,
      title: data.Title,
      description: data.Type,
      poster: data.Poster,
      showSecondButton: true,
      onClickButton: () => {}
    })
  }
let history = useHistory();
  return(
    <div className="card" ref={customRef}>
      <div className="card__inner">
        <span 
          style={{
            position:'absolute',
            top: 7,
            right: 10,
            padding: '4px 12px',
            background: '#e91e63b3',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: 100
          }}
          onClick={handleShowModal}>i</span>
        <img 
          onClick={() => history.push(`/${data.imdbID}`)}
          className="card__img" 
          src={data.Poster} 
          alt={data.Title} 
        />
      </div>
    </div>
  );
}

Card.propTypes = {
  data: PropTypes.object
}

Card.defaultProps = {
  data: {
    Poster: "https://i0.wp.com/194.163.183.129/wp-content/uploads/2021/10/118270.jpg?quality=90&resize=150,210",
    Title: "Superman",
    Type: "Movie",
    Yeear: "2021",
    imdbID: "tt2975590"
  }
}



export default Card;
