import actionType from './constant';

const initialState = {
  title: '', // title of modal
  description: '', // description of modal
  onClickButton: null, // action button primary in modal,
  poster: '',
  id: '',
  show: false, // handle for visibility
  buttonTitle: 'ok', // button primary title
  buttonSecondTitle: 'batal', // button secondary title
  customButton: null, // a custom component for button
  showSecondButton: false, // handle for visibility button secondary
  onClickSecondButton: null // action button secondary
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_MODAL:
      return {
        ...state,
        ...action.value
      };
    case actionType.CLEAR_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modal;