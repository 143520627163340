import actionType from './constant';

/**
 * an Action for set MODAL reducer
 * @param {*} value
 */
export const setModal = value => ({
  type: actionType.SET_MODAL,
  value
});

/**
 * an Action for clear MODAL reducer
 * @param {*} value
 */
export const clearModal = () => ({
  type: actionType.CLEAR_MODAL
});