import { React, useState, useCallback, useRef} from "libraries";
import { AppLayout, Card, BlockSearch } from "components";

const HomePages = () => {
  const [query, setQuery] = useState("batman")
  const [pageNumber, setPageNumber] = useState(1);  

  const {
    movies,
    loading,
    error
  } = BlockSearch(query, pageNumber);

  const observer = useRef();
  const lastMoviesRef = useCallback(node => {
    if(loading) return
    if(observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && movies.length > 5) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    });

    if(node) observer.current.observe(node)
  }, [loading, movies.length])


  
  const handlerSearch = (e) => {
    setQuery(e.target.value);
    setPageNumber(1)
  }

  return(
    <AppLayout title="Homepage">
      <div style={{padding:14}}>
        <input type="text" value={query} onChange={handlerSearch} />
      </div>
      <div className="pagesHome">
        <div className="pagesHome__row">
          {movies.map((item, index) => {
            if(movies.length === index + 1) {
              return <Card data={item} key={index} customRef={lastMoviesRef} />
            } else {
              return <Card data={item} key={index} />
            }
          })}
          <div style={{display: 'block'}}>{loading && 'Loading'}</div>
          <div>{error && 'error'}</div>
        </div>
      </div>
    </AppLayout>
  )
};

export default HomePages