import {combineReducers} from 'libraries';

// Reducer
import movies from './movies/reducer';
import modal from './modal/reducer';

const reducer = combineReducers({
  movies,
  modal
});

// Action
export * from './movies/actions';
export * from './modal/actions';

// Selector
export * from './movies/selector';
export * from './modal/selector';

export default reducer;