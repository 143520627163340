import { BrowserRouter, Switch, Route, React } from "libraries";
import { routes } from 'routes';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((item, i) => (
          <Route 
            key={i} 
            path={item.path}
            component={item.component} 
            exact={true} 
            name={item.name}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
