import actionType from "./constant";

const initState = null;

const movies = (state = initState, action) => {
  switch(action.type) {
    case actionType.SET_MOVIES:
      return{
        ...state,
        ...action.value
      };
    case actionType.CLEAR_MOVIES:
      return initState
    default:
      return state;
  }
}

export default movies;
