/* eslint-disable react-hooks/exhaustive-deps */
import { AppLayout } from "components";
import { getDetailMovieList } from "configs/services";
import { connect, React } from "libraries";
import { movieSelector } from "modules";

const SinglePage = (props) => {

  React.useEffect(() => {
    const { match: { params }} = props;
    const payload = {
      params: {
        apikey: 'd1490605',
        i: params.id,
        page: '1'
      }
    }
    getDetailMovieList(payload)
  }, []);

  return(
    <AppLayout title="Assigment - Detail">
      {props.resultMovie !== null ? (
        <div className="singlePages">
        <div className="singlePages__header">
          <span className="singlePages__header--closebtn" onClick={() => props.history.goBack(-1)}>X</span>
          <img className="singlePages__headerImg" src={props.resultMovie && props.resultMovie.Poster} alt={props.resultMovie && props.resultMovie.Title} />
          <div className="singlePages__headerPoster">
            <img src={props.resultMovie && props.resultMovie.Poster} alt={props.resultMovie && props.resultMovie.Title} />
          </div>
          <div className="singlePages__headerTitle">
            <h2>{props.resultMovie && props.resultMovie.Title}</h2>
          </div>
        </div>
        <div className="singlePages__content">
          <h6 className="singlePages__content--title">Movie Detail</h6>
            <div className="formGroup"><span>{props.resultMovie && props.resultMovie.Plot}</span></div>
          <div className="">
            <div className="formGroup" ><span style={{fontWeight:'bold'}}>Title</span><span>{props.resultMovie && props.resultMovie.Title}</span></div>
            <div className="formGroup" ><span style={{fontWeight:'bold'}}>Genre</span><span>{props.resultMovie && props.resultMovie.Genre}</span></div>
            <div className="formGroup" ><span style={{fontWeight:'bold'}}>Released</span><span>{props.resultMovie && props.resultMovie.Released}</span></div>
            <div className="formGroup" ><span style={{fontWeight:'bold'}}>Language</span><span>{props.resultMovie && props.resultMovie.Language}</span></div>
          </div>
        </div>
      </div>
      ) : 'Loading..'}
    </AppLayout>
  )
};

const reduxState = state => ({
  resultMovie: movieSelector(state)
});

export default connect(reduxState, null)(SinglePage);
