import { React } from 'libraries';
import { getMovieList } from "configs/services";
import axios from 'axios';

const BlockSearch = (query, pageNumber) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [movies, setMovies] = React.useState([]);

  React.useEffect(() =>{
    setMovies([])
  }, [query])

  React.useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel;
    const payload = {
      params: {
        apikey: 'd1490605',
        s: query,
        page: pageNumber,
      },
      cancelToken: new axios.CancelToken(c => cancel = c)
    }
    getMovieList(payload).then(res => {
      
      if(res.Response === "True") {
        setMovies(prevMovies => {
          return [...new Set([...prevMovies, ...res.Search.map(item => item)])]
        })
      }
    }).catch(e => {
       if(axios.isCancel(e)) return;
       setError(false)
    }).finally(() => {
      setLoading(false)
    })
    return () => cancel()
  }, [query, pageNumber])

  return {movies, loading, error}
};

export default BlockSearch;